<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pendukung</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="nik_saksi">NIK : </label>
                  <input
                    v-model="form.nik_saksi"
                    type="text"
                    class="form-control"
                    name="nik_saksi"
                    placeholder="NIK"
                    minlength="16"
                    maxlength="16"
                    required
                    :class="{
                      'is-invalid': formValidate.nik_saksi,
                    }"
                  />
                  <small
                    class="invalid-feedback"
                    v-html="formValidate.nik_saksi"
                  ></small>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="nama_saksi">Nama: </label>
                  <input
                    v-model="form.nama_saksi"
                    type="text"
                    class="form-control"
                    placeholder="Nama"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.nama_saksi,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="jenkel_saksi">Jenis Kelamin : </label>
                  <select
                    v-model="form.jenkel_saksi"
                    class="form-control"
                    name="jenkel_saksi"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.jenkel_saksi,
                    }"
                  >
                    <option value="" style="text-transform: uppercase">
                      Pilih
                    </option>
                    <option value="Laki-Laki" style="text-transform: uppercase">
                      Laki-Laki
                    </option>
                    <option value="Perempuan" style="text-transform: uppercase">
                      Perempuan
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="hp_saksi">Telp/HP : </label>
                  <input
                    v-model="form.hp_saksi"
                    type="number"
                    class="form-control"
                    name="hp_saksi"
                    placeholder="Telp/HP"
                    style="text-transform: uppercase"
                    :class="{
                      'is-invalid': formValidate.hp_saksi,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="alamat_saksi">Alamat: </label>
                  <textarea
                    v-model="form.alamat_saksi"
                    class="form-control"
                    name="alamat_saksi"
                    id="alamat_saksi"
                    placeholder="Alamat"
                    rows="3"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.alamat_saksi,
                    }"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kab_saksi">Kabupaten: </label>
                  <input
                    v-model="form.kab_saksi"
                    class="form-control"
                    readonly
                    name="kab_saksi"
                    id="kab_saksi"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.kab_saksi,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kec_saksi">Kecamatan : </label>
                  <select
                    v-model="form.kec_saksi"
                    name="kec_saksi"
                    id="kec_saksi"
                    v-on:change="fetchKelurahan($event)"
                    class="form-control"
                    :class="{
                      'is-invalid': formValidate.kec_saksi,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kecamatan in listKecamatan"
                      :value="kecamatan.nama_kecamatan"
                      :key="kecamatan.id_kec"
                      :data-key="kecamatan.id_kec"
                    >
                      {{ kecamatan.nama_kecamatan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="kel_saksi">Desa : </label>
                  <select
                    v-model="form.kel_saksi"
                    name="kel_saksi"
                    id="kel_saksi"
                    class="form-control"
                    :class="{
                      'is-invalid': formValidate.kel_saksi,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kelurahan in listKelurahan"
                      :value="kelurahan.nama_kelurahan"
                      :key="kelurahan.nama_kelurahan"
                    >
                      {{ kelurahan.nama_kelurahan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="tps_saksi">TPS : </label>
                  <select
                    v-model="form.tps_saksi"
                    class="form-control"
                    name="tps_saksi"
                    placeholder="Nomor TPS"
                    required
                    :class="{ 'is-invalid': formValidate.tps_saksi }"
                  >
                    <option value="" disabled>Pilih Nomor TPS</option>
                    <option
                      v-for="tps in getTpsOptions()"
                      :key="tps"
                      :value="tps"
                    >
                      {{ tps }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_saksi: "",
        nik_saksi: "",
        nama_saksi: "",
        jenkel_saksi: "",
        alamat_saksi: "",
        kab_saksi: "Kab. Tojo Una-Una",
        kec_saksi: "",
        kel_saksi: "",
        tps_saksi: "",
        hp_saksi: "",
      },
      id_pendukung: "",
      loading: false,
      showUserAccess: true,
      formValidate: [],
      listKecamatan: [],
      listKelurahan: [],
    };
  },
  mounted() {
    this.fetchKecamatan();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/pendukung/detail/" + this.id_pendukung)
        .then((response) => {
          this.form.nik_saksi = response.data.data.nik_pendukung;
          this.form.nama_saksi = response.data.data.nama_pendukung;
          this.form.jenkel_saksi = response.data.data.jenkel_pendukung;
          this.form.alamat_saksi = response.data.data.alamat_pendukung;
          this.form.kab_saksi = response.data.data.kab_pendukung;
          this.form.kec_saksi = response.data.data.kec_pendukung;
          this.form.kel_saksi = response.data.data.kel_pendukung;
          this.form.tps_saksi = response.data.data.tps_pendukung;
          this.form.hp_saksi = response.data.data.hp_pendukung;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/saksi/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKecamatan() {
      axios
        .get("/v1/daerah/kecamatan/7209", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKelurahan(event) {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const key = selectedOption.getAttribute("data-key");

      console.log(key);
      axios
        .get("/v1/daerah/kelurahan/" + key, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    getTpsOptions() {
      return Array.from({ length: 15 }, (_, index) => {
        const tpsNumber = "TPS " + (index + 1).toString().padStart(2, "0");
        return tpsNumber;
      });
    },
  },
};
</script>
