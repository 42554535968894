var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nik_saksi"}},[_vm._v("NIK : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nik_saksi),expression:"form.nik_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.nik_saksi,
                  },attrs:{"type":"text","name":"nik_saksi","placeholder":"NIK","minlength":"16","maxlength":"16","required":""},domProps:{"value":(_vm.form.nik_saksi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nik_saksi", $event.target.value)}}}),_c('small',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.formValidate.nik_saksi)}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nama_saksi"}},[_vm._v("Nama: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_saksi),expression:"form.nama_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.nama_saksi,
                  },staticStyle:{"text-transform":"uppercase"},attrs:{"type":"text","placeholder":"Nama","required":""},domProps:{"value":(_vm.form.nama_saksi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_saksi", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"jenkel_saksi"}},[_vm._v("Jenis Kelamin : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.jenkel_saksi),expression:"form.jenkel_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.jenkel_saksi,
                  },staticStyle:{"text-transform":"uppercase"},attrs:{"name":"jenkel_saksi","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "jenkel_saksi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticStyle:{"text-transform":"uppercase"},attrs:{"value":""}},[_vm._v(" Pilih ")]),_c('option',{staticStyle:{"text-transform":"uppercase"},attrs:{"value":"Laki-Laki"}},[_vm._v(" Laki-Laki ")]),_c('option',{staticStyle:{"text-transform":"uppercase"},attrs:{"value":"Perempuan"}},[_vm._v(" Perempuan ")])])])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"hp_saksi"}},[_vm._v("Telp/HP : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hp_saksi),expression:"form.hp_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.hp_saksi,
                  },staticStyle:{"text-transform":"uppercase"},attrs:{"type":"number","name":"hp_saksi","placeholder":"Telp/HP"},domProps:{"value":(_vm.form.hp_saksi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hp_saksi", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"alamat_saksi"}},[_vm._v("Alamat: ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.alamat_saksi),expression:"form.alamat_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.alamat_saksi,
                  },staticStyle:{"text-transform":"uppercase"},attrs:{"name":"alamat_saksi","id":"alamat_saksi","placeholder":"Alamat","rows":"3","required":""},domProps:{"value":(_vm.form.alamat_saksi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "alamat_saksi", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kab_saksi"}},[_vm._v("Kabupaten: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kab_saksi),expression:"form.kab_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.kab_saksi,
                  },staticStyle:{"text-transform":"uppercase"},attrs:{"readonly":"","name":"kab_saksi","id":"kab_saksi","required":""},domProps:{"value":(_vm.form.kab_saksi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kab_saksi", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kec_saksi"}},[_vm._v("Kecamatan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kec_saksi),expression:"form.kec_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.kec_saksi,
                  },attrs:{"name":"kec_saksi","id":"kec_saksi"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kec_saksi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.fetchKelurahan($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.listKecamatan),function(kecamatan){return _c('option',{key:kecamatan.id_kec,attrs:{"data-key":kecamatan.id_kec},domProps:{"value":kecamatan.nama_kecamatan}},[_vm._v(" "+_vm._s(kecamatan.nama_kecamatan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"kel_saksi"}},[_vm._v("Desa : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kel_saksi),expression:"form.kel_saksi"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.formValidate.kel_saksi,
                  },attrs:{"name":"kel_saksi","id":"kel_saksi"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kel_saksi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.listKelurahan),function(kelurahan){return _c('option',{key:kelurahan.nama_kelurahan,domProps:{"value":kelurahan.nama_kelurahan}},[_vm._v(" "+_vm._s(kelurahan.nama_kelurahan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tps_saksi"}},[_vm._v("TPS : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tps_saksi),expression:"form.tps_saksi"}],staticClass:"form-control",class:{ 'is-invalid': _vm.formValidate.tps_saksi },attrs:{"name":"tps_saksi","placeholder":"Nomor TPS","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tps_saksi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Pilih Nomor TPS")]),_vm._l((_vm.getTpsOptions()),function(tps){return _c('option',{key:tps,domProps:{"value":tps}},[_vm._v(" "+_vm._s(tps)+" ")])})],2)])])])]),_c('div',{staticClass:"panel-footer"},[_c('button',{staticClass:"btn btn-success pull-right",attrs:{"variant":"primary"},on:{"click":_vm.postData}},[(_vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Submit ")]),_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form Pendukung")])])
}]

export { render, staticRenderFns }